export default {
  'news.nameSidebar': 'Information',
  'news.info': 'Information detail',
  'news.edit': 'Update information',
  'news.add': 'Create information',
  'news.title': 'Title',
  'news.title-pl': 'Enter title',
  'news.short-desc': 'Short description',
  'news.short-desc-pl': 'Enter short description',
  'news.view-with': 'Show with',
  'news.cover': 'Cover image',
  'news.cover-pl': 'Upload image',
  'news.cover-desc': 'Image description',
  'news.cover-desc-pl': 'Enter image description',
  'news.view-with-pl': 'Enter who show with',
  'news.description': 'Description',
  'news.documentAccepts': 'Show with',
  'news.creator': 'Creator',
  'news.createdAt': 'Created at',
  'news.action': 'Action',
  'news.delete.title': 'Delete information',
  'news.delete.content': 'Do you want to delete this information',
  'news.add-image': 'Upload image',
  'news.all-clubs': 'All clubs',
};
