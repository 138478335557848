export default {
  'revenue.name': 'Tài chính',
  'revenue.title': 'Quản lý tài chính',
  'revenue.titleDatePicker': 'Thời gian',
  'revenue.create': 'Thêm mới',
  'revenue.information': 'Chi tiết',
  'revenue.member': 'Tên hội viên',
  'revenue.product': 'Sản phẩm tài trợ',
  'revenue.unitPrice': 'Thành tiền (VNĐ)',
  'revenue.unit': 'Đơn vị',
  'revenue.quantity': 'Số lượng',
  'revenue.user': 'Người nhận',
  'revenue.action': 'Hành động',
  'revenue.intoMoney': 'Thành tiền (VNĐ)',
  'revenue.netRevenueDate': 'Ngày giao dịch',
  'revenue.code': 'Mã khách hàng',
  'revenue.emailAddress': 'Email',
  'revenue.daypay': 'Ngày đóng',
  'revenue.content': 'Nội dung',
  'revenue.attach': 'Đính kèm',
  'revenue.tagName': 'Khách hàng',
  'revenue.programCode': 'Mã chương trình',
  'revenue.delete.title': 'Xóa tài chính',
  'revenue.delete.content': 'Bạn có muốn xóa hóa đơn này?',
  'revenue.type': 'Phân loại',
  'revenue.type.revenue': 'Doanh thu',
  'revenue.type.discount': 'Chiết khấu',
  'history-revenues.total.discount': 'Chiết khấu đã chi/ Tổng chiết khấu',
  'revenue.total': 'Tổng cộng (VNĐ)',
  'revenue.auto': 'Tự động',
  'revenue.type.sponsor': 'Tài trợ',
  'revenue.money': 'Số tiền',
  'revenue.record': 'Ghi nhận tài trợ',
  'revenue.yes': 'Có',
  'revenue.no': 'Không',
  'history-revenues.total.sponsor': 'Tài trợ thực/ Lũy kế tài trợ',
  'history-revenues.action': 'Hành động',
};
