import { routerFormActivities } from '@view/Activities/component/FromActivities/router';
import { routerActivitiesListMember } from '@view/Activities/component/ListMember/router';
import { routerActivities } from '@view/Activities/router';
/* eslint-disable @typescript-eslint/comma-dangle */
import { routerForgot, routerForgotToken } from '@view/Auth/ForgotPassword/router';
import { routerLogin, routerLoginHome } from '@view/Auth/Login/router';
import { routerViewProfile } from '@view/Auth/Profile/router';
import { routerResetForgotPass } from '@view/Auth/ResetForgotPass/router';
import {
  routerCalendar,
  routerEventCalendarAdd,
  routerEventCalendarEdit,
  routerEventCalendarInfo,
  routerMeetingScheduleAdd,
  routerMeetingScheduleEdit,
  routerMeetingScheduleInfo,
} from '@view/Calendar/router';
import { routerCategory } from '@view/Category/router';
import { routerFormClubs, routerFormClubsAdd } from '@view/Clubs/component/FormClubs/router';
import {
  routerFormClubsExecutiveCommittee,
  routerFormClubsExecutiveCommitteeInfo,
} from '@view/Clubs/component/ListExecutiveCommittee/component/FormExecutiveCommittee/router';
import { routerFormClubsMemberInfo } from '@view/Clubs/component/ListMembers/component/Member/component/FormMember/router';
import {
  routerFormClubsMemberPersonal,
  routerFormClubsMemberPersonalInfo,
} from '@view/Clubs/component/ListMembers/component/MemberPersonal/component/FormMemberPersonal/router';
import { routerFormClubsPartnerInfo } from '@view/Clubs/component/ListMembers/component/Partner/component/FormPartner/router';
import { routerListClubsMemberTab } from '@view/Clubs/component/ListMembers/router';
import { routerResultSearchPage } from '@view/Clubs/component/ResultSearchPage/router';
import { routerClubs } from '@view/Clubs/router';
import { routerContacts } from '@view/Contacts/router';
import { routerCost } from '@view/Cost/router';
import { routerDiscount } from '@view/Discount/router';
import { routerDetailFeedbackPage } from '@view/Feedback/component/DetailPage/router';
import { routerFeedback } from '@view/Feedback/router';
import { routerHomeFormAdd } from '@view/Home/component/FromAdd/router';
import { routerHome } from '@view/Home/router';
import { routerLevels } from '@view/Levers/router';
import {
  routerFormExecutiveCommittee,
  routerFormExecutiveCommitteeInfo,
} from '@view/MemberAndPartner/component/ExecutiveCommittee/component/FormExecutiveCommittee/router';
import { routerHistoryFees } from '@view/MemberAndPartner/component/HistoryFees/router';
import { routerHistoryRevenues } from '@view/MemberAndPartner/component/HistoryNetRevenues/router';
import {
  routerFormClubsExecutiveCommitteeClubInfo,
  routerFormClubsExecutiveCommitteeClubs,
} from '@view/MemberAndPartner/component/ListExecutiveCommittee/component/FormExecutiveCommittee/router';
import {
  routerFormMember,
  routerFormMemberInfo,
} from '@view/MemberAndPartner/component/Member/component/FormMember/router';
import {
  routerFormMemberPersonal,
  routerFormMemberPersonalInfo,
} from '@view/MemberAndPartner/component/MemberPersonal/component/FormMemberPersonal/router';
import {
  routerFormPartner,
  routerFormPartnerInfo,
} from '@view/MemberAndPartner/component/Partner/component/FormPartner/router';
import { routerMemberAndPartnerTabs } from '@view/MemberAndPartner/router';
import { routerNetRevenues } from '@view/NetRevenues/router';
import { routerNews, routerNewsForm, routerNewsFormAdd } from '@view/News/router';
import {
  routerNotification,
  routerNotificationAdd,
  routerNotificationEdit,
  routerNotificationInfo,
} from '@view/Notification/router';
import { routerPageError } from '@view/PageError/router';
import { routerRankMember } from '@view/RankMember/router';
import { routerRoles, routerRolesAdd, routerRolesEdit, routerRolesInfo } from '@view/Roles/router';
import { routerSetting } from '@view/Setting/router';
import { routerUser } from '@view/User/router';

import { IRouter } from './interface';

export const privatePage: IRouter[] = [
  routerViewProfile,
  routerHome,
  routerNews,
  routerNewsForm,
  routerNewsFormAdd,
  routerUser,
  routerRoles,
  routerRolesAdd,
  routerRolesEdit,
  routerRolesInfo,
  routerClubs,
  routerMemberAndPartnerTabs,
  routerActivities,
  routerFormActivities,
  routerActivitiesListMember,
  routerCost,
  routerNetRevenues,
  routerNotification,
  routerNotificationAdd,
  routerNotificationEdit,
  routerNotificationInfo,
  routerFeedback,
  routerCalendar,
  routerFormClubsAdd,
  routerMeetingScheduleAdd,
  routerMeetingScheduleEdit,
  routerMeetingScheduleInfo,
  routerEventCalendarAdd,
  routerEventCalendarEdit,
  routerEventCalendarInfo,
  routerCategory,
  routerFormExecutiveCommittee,
  routerFormMember,
  routerFormPartner,

  routerListClubsMemberTab,
  routerSetting,
  routerDiscount,
  routerRankMember,
  routerLevels,
  routerFormExecutiveCommitteeInfo,
  routerFormMemberInfo,
  routerFormPartnerInfo,
  routerHistoryFees,
  routerHistoryRevenues,
  routerFormClubs,
  routerContacts,
  routerFormMemberPersonal,
  routerFormMemberPersonalInfo,
  routerFormClubsMemberInfo,
  routerFormClubsMemberPersonal,
  routerFormClubsMemberPersonalInfo,
  routerFormClubsPartnerInfo,
  routerFormClubsExecutiveCommittee,
  routerFormClubsExecutiveCommitteeInfo,
  routerFormClubsExecutiveCommitteeClubs,
  routerFormClubsExecutiveCommitteeClubInfo,
  routerResultSearchPage,
  routerDetailFeedbackPage,
  routerHomeFormAdd,
  routerPageError,
];

export const publicPage: IRouter[] = [
  routerLoginHome,
  routerLogin,
  routerForgot,
  routerForgotToken,
  routerResetForgotPass,
  routerPageError,
];
