import React from 'react';
import * as Icon from 'react-feather';

import PermissionCode from '@modules/roles/contantsPermissions';
import { IRouter } from '@routers/interface';

export const routerNetRevenues: IRouter = {
  path: '/revenue/:tab',
  loader: React.lazy(() => import('./index')),
  exact: true,
  name: 'revenue.name', //translate here for breadcrumb and sidebar
  menu: {
    activePath: '/revenue/',
    generatePath: () => 'revenue/checkPermission', //default url
    icon: <Icon.Trello />,
  },
  permissionCode: [PermissionCode.REVENUE_SEARCH, PermissionCode.SPONSORS_SEARCH],
};
