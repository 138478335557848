import React from 'react';

import PermissionCode from '@modules/roles/contantsPermissions';
import { IRouter } from '@routers/interface';

export const routerFormClubsAdd: IRouter = {
  path: '/clubs/:type',
  loader: React.lazy(() => import('./index')),
  exact: true,
  name: 'formClubs.view', //translate here for breadcrumb and sidebar
  permissionCode: PermissionCode.CLUBS_CREATE,
};

export const routerFormClubs: IRouter = {
  path: '/clubs/:type/:id',
  loader: React.lazy(() => import('./index')),
  exact: true,
  name: 'formClubs.view', //translate here for breadcrumb and sidebar
  permissionCode: [PermissionCode.CLUBS_UPDATE, PermissionCode.CLUBS_SEARCH],
};
