import React from 'react';

import PermissionCode from '@modules/roles/contantsPermissions';
import { IRouter } from '@routers/interface';

export const routerFormClubsMemberPersonal: IRouter = {
  path: '/clubs/list-members/form-member-personal/:type/:id',
  loader: React.lazy(() => import('./index')),
  exact: true,
  name: 'memberPersonal.add', //translate here for breadcrumb and sidebar
  permissionCode: [
    PermissionCode.CLUBS_UPDATE_MEMBER,
    PermissionCode.CLUBS_CREATE_MEMBER,
    PermissionCode.CLUBS_VIEW,
  ],
};

export const routerFormClubsMemberPersonalInfo: IRouter = {
  path: '/clubs/list-members/form-member-personal/:type/:id',
  loader: React.lazy(() => import('./index')),
  exact: true,
  name: 'memberPersonal.view', //translate here for breadcrumb and sidebar
  permissionCode: [
    PermissionCode.CLUBS_UPDATE_MEMBER,
    PermissionCode.CLUBS_CREATE_MEMBER,
    PermissionCode.CLUBS_VIEW,
  ],
};
