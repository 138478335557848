import React from 'react';
import * as Icon from 'react-feather';

import PermissionCode from '@modules/roles/contantsPermissions';
import { IRouter } from '@routers/interface';

export const routerListClubsMemberTab: IRouter = {
  path: '/clubs/list-members/:tab/:id',
  loader: React.lazy(() => import('./index')),
  exact: true,
  // generatePath: () => 'clubs/list-members/checkPermission', //default url

  // menu: {
  //   activePath: 'clubs/list-members',
  //   generatePath: () => 'clubs/list-members/checkPermission', //default url
  // },
  menu: {
    activePath: 'list-members',
    icon: <Icon.Users />,
    generatePath: () => '/clubs/list-members/executive-committee/checkId',
  },
  clubsRouter: true,
  name: 'memberAndPartner.name', //translate here for breadcrumb and sidebar
  permissionCode: [
    PermissionCode.CLUBS_CREATE_PARTNER,
    PermissionCode.CLUBS_UPDATE_PARTNER,
    PermissionCode.CLUBS_VIEW,
  ],
};
